import React from 'react';

const DemoAlert = () => {
    return (
        <div style={{position:'absolute',bottom:'100px',right:'100px',zIndex:'99'}} className="animDemo">
            DEMÓ VERZIÓ
        </div>
    );
};

export default DemoAlert;